import React, {
    useCallback,
    useEffect,
    useRef,
    useState
}                           from 'react';
import classNames           from 'classnames';
import PropTypes            from 'prop-types';
import { Link }             from 'react-router-dom';
import { CSSTransition }    from 'react-transition-group';
import {
    disableBodyScroll,
    enableBodyScroll
}                           from 'body-scroll-lock';
import {
    useDispatch,
    useSelector
}                           from 'react-redux';
import Spinner              from '../Spinner';
import CatalogIcon          from '../../assets/icon/CatalogIcon';
// import MicroIcon            from '../../assets/icon/MicroIcon';
import SearchIcon           from '../../assets/icon/SearchIcon';
import DocumentIcon         from '../../assets/icon/DocumentIcon';
import OnlineIcon           from '../../assets/icon/OnlineIcon';
import CartIcon             from '../../assets/icon/CartIcon';
import HeartIcon            from '../../assets/icon/HeartIcon';
import ProfileIcon          from '../../assets/icon/ProfileIcon';
import MarkerIcon           from '../../assets/icon/MarkerIcon';
import product              from '../../assets/images/product.jpg';
import Button               from '../Button';
import styles               from '../ListEditingCard/ListEditingCard.module.scss';
import PopupCart            from '../PopupCart';
import LocationPopup        from '../LocationPopup';
import accountPeople        from '../../assets/images/accountPeople.png';
import { cartListSelector } from '../../store/selectors/Cart';
import { addInCart }        from '../../store/actions/addInCart';
import { getCart }          from '../../store/actions/Cart';
import history              from '../../history';
import {
    isLoadingSearchProductsSelector,
    searchProductsListSelector,
    searchStringSelector
}                           from '../../store/selectors/Products';
import {
    clearSearch,
    getSearchProductsList
}                           from '../../store/actions/Products';
import { useLoading }       from '../../utils/useLoading';
import { MODALS }           from '../../constants';
import { useModal }         from '../../utils/useModal';
import EyeIcon              from '../../assets/icon/EyeIcon';
import stylesHeader         from '../header/header.module.scss';
import style                from './search.module.scss';

// eslint-disable-next-line react/prop-types
function Search({ isDesktop, isTablet, toggleCatalog, checkLogin, city = 'Одеса', searchRef, handleVisuallyImpaired }) {
    const dispatch = useDispatch();
    const searchProductsList = useSelector(searchProductsListSelector);
    const isLoadingSearch = useSelector(isLoadingSearchProductsSelector);
    const searchString = useSelector(searchStringSelector);
    const [ positionChange, setPositionChange ] = useState(0);
    const [ openSearch, setOpenSearch ] = useState(false);
    const [ cartPopup, setCartPopup ] = useState(false);
    const [ location, setLocation ] = useState(false);
    const [ visuallyImpaired, setVisuallyImpaired ] = useState(false);
    const popupCartWrapper = useRef(null);
    const input = useRef();
    const init = useRef(true);
    const { loading: load  } = useLoading();
    const popupBody = useRef();
    const popupBodyScroll = useRef(null);
    const checkToken = document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1];

    const { toggleModal } = useModal();

    useEffect(() => {
        const unlisten = history.listen(() => {
            setOpenSearch(false);
        });

        return () => {
            unlisten();
        };
    }, [ history ]);

    const locationPopupHandle = useCallback((state) => {
        if (typeof state === 'object') {
            setLocation(true);
        } else {
            setLocation(false);
        }
    }, []);

    const closeCart = useCallback(({ target }) => {
        if (init.current) {
            init.current = false;

            return;
        }

        if (popupCartWrapper?.current && !popupCartWrapper?.current.contains(target)) {
            setCartPopup(false);
            init.current = true;
        }
    }, [ init.current ]);

    useEffect(() => {
        document.addEventListener('click', (e) => {
            closeModal(e);
            closeCart(e);
        });

        return () => document.removeEventListener('click', () => {
            closeModal();
            closeCart();
        });
    }, []);

    const toggleCartPopup = useCallback(() => {
        setCartPopup(prev => !prev);
        init.current = !cartPopup;
    }, []);

    const closeModal = useCallback((e) => {
        if (!popupBody?.current) {
            return;
        }

        if (!popupBody?.current.contains(e.target)) {
            enableBodyScroll(document.body);
            input.current.value = '';
            setOpenSearch(false);
        }
    }, [ popupBody ]);

    const closeSearch = useCallback(e => {
        if (e.target.closest('button')) return;
        // e.preventDefault();
        setOpenSearch(false);
        input.current.value = '';
        enableBodyScroll(document.body);
    }, []);

    const search = useCallback(async ({ target }) => {
        if (target.value.length > 2) {
            setOpenSearch(true);

            dispatch(getSearchProductsList({ searchText: target.value }));

            if (popupBodyScroll?.current) {
                disableBodyScroll(document.body);
                enableBodyScroll(popupBodyScroll?.current);
            }
        } else {
            if (searchString) dispatch(clearSearch());
            setOpenSearch(false);
            enableBodyScroll(document.body);
        }
    }, [ popupBodyScroll?.current, openSearch ]);

    const renderSpinnerOrEmptyList = useCallback(() => {
        if (isLoadingSearch) {
            return (
                <CSSTransition
                    timeout={300}
                    in={isLoadingSearch && !searchProductsList.length}
                    unmountOnExit
                    classNames='item'
                    mode={'out-in'}
                    >
                    <Spinner />
                </CSSTransition>
            );
        }

        return <h2 className={style['empty-list-title']}>Поки що немає продуктів</h2>;
    }, [ isLoadingSearch, searchProductsList.length ]);

    const handleClick = useCallback(async (e) => {
        const { target } = e;

        e.preventDefault();

        if (target.closest('button')) {
            const cartInfo = target.closest('button').getAttribute('data-obj');
            const session = localStorage.getItem('sessionId');

            if (cartInfo) {
                const obj = JSON.parse(cartInfo);

                const cart = localStorage.getItem('cart');

                if (cart) {
                    localStorage.setItem('cart', JSON.stringify([ ...JSON.parse(cart), obj ]));
                    // eslint-disable-next-line no-shadow
                    const cartUpdate = localStorage.getItem('cart');

                    const arr = JSON.parse(cartUpdate);

                    const result = arr.reduce((acc, curr) => {
                        // eslint-disable-next-line no-shadow
                        const item = acc.find(item => item.productId === curr.productId);

                        if (item) {
                            item.quantity += curr.quantity;
                        } else {
                            acc.push(curr);
                        }

                        return acc;
                    }, []);

                    localStorage.setItem('cart', JSON.stringify(result));
                } else {
                    localStorage.setItem('cart', JSON.stringify([ obj ]));
                }

                target.closest('button').classList.add(style.click);

                await dispatch(addInCart({ ...obj, ...(session && { sessionId: session }) }));

                await dispatch(getCart());

                setTimeout(() => {
                    target.closest('button').classList.remove(style.click);
                }, 1200);
            }
        } else {
            const cartInfo = target.closest('div').getAttribute('data-link') || false;

            if (cartInfo) {
                history.push(cartInfo);
            } else {
                const id =  target.closest('.card') ? target.closest('.card').getAttribute('data-id') : false;

                if (id) history.push(`/product/${id}`);
            }
        }
    }, []);

    useEffect(() => {
        const unlisten = history.listen(() => {
            setCartPopup(false);
        });

        return () => {
            unlisten();
        };
    }, [ history ]);

    const togglePopup = useCallback(({ target }) => {
        const modal = target.closest('button').getAttribute('data-id');

        const getPopup = () => {
            switch (modal) {
                case 'POPUP_CONSULTATION':
                    return MODALS.POPUP_CONSULTATION;
                case 'POPUP_DRAG':
                    return MODALS.POPUP_DRAG;
                default:
                    return false;
            }
        };


        if (modal) {
            // eslint-disable-next-line no-shadow
            const checkToken = document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1];

            if (modal === 'POPUP_DRAG') {
                if (checkToken) {
                    toggleModal({ id: getPopup() });
                } else  {
                    const expiryDate = new Date();

                    expiryDate.setTime(expiryDate.getTime() + (2 * 24 * 60 * 60 * 1000));
                    document.cookie = `recipe=true; expires=${expiryDate.toUTCString()}; path=/`;
                    toggleModal({ id: MODALS.POPUP_LOGIN });
                }

                return;
            }

            toggleModal({ id: getPopup() });
        }
    }, []);

    const { items = [] }  = useSelector(cartListSelector);

    const handlePositionChange = useCallback(() => {
        // eslint-disable-next-line react/prop-types
        const { top, left, width, height } = searchRef.current.getBoundingClientRect();

        setPositionChange({ top, left, width, height });
    }, [ searchRef, load ]);

    const handleSmall = useCallback(() => {
        document.querySelector('html').className = '';
        document.querySelector('html').classList.add('small');
    }, []);

    const handleBig = useCallback(() => {
        document.querySelector('html').className = '';
        document.querySelector('html').classList.add('big');
    }, []);

    const mobileVisibleImpaired = useCallback(() => {
        setVisuallyImpaired(() => (!visuallyImpaired));
        handleVisuallyImpaired();
    }, [ visuallyImpaired ]);

    const handleBase = useCallback(() => {
        document.querySelector('html').className = '';
        document.querySelector('html').classList.add('base');
    }, []);

    useEffect(() => {
        handlePositionChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ searchRef, load ]);

    useEffect(() => {
        window.addEventListener('resize', handlePositionChange);

        return () => {
            window.removeEventListener('resize', handlePositionChange);
        };
    }, []);

    return (
        <div className={style.search} ref={searchRef}>
            <div className={classNames(style.container, style['search-wrapper'])}>
                <div className={style['btn-container']}>
                    <button className={classNames(style['button-catalog'], '--border')} onClick={toggleCatalog}>
                        <CatalogIcon />
                        Каталог
                    </button>
                    {
                        isTablet &&
                        <div className={classNames(style['input-wrapper'], '--border')}>
                            <input
                                type={'text'}
                                ref={input}
                                onChange={search}
                                placeholder={'пошук по 25 000 найменувань'}
                            />

                            <CSSTransition
                                timeout={300}
                                in={openSearch}
                                unmountOnExit
                                classNames='item'
                                mode={'out-in'}
                            >
                                <div className={style['input-search-popup']} onClick={handleClick}>
                                    <div className={classNames(style.bg, '--transparent')} />
                                    <div ref={popupBody} className={style['input-search-popup__wrapper']}>
                                        {!searchProductsList.length
                                            ? renderSpinnerOrEmptyList()
                                            : null
                                        }
                                        <div className={style['input-search-popup__wrapper__card']}>
                                            {searchProductsList.slice(0, 3).map((item, i) => (
                                                // eslint-disable-next-line react/jsx-no-bind
                                                <div key={item?.id} className={`card ${  styles['list-editing-card__card']}`} data-id={item?.id}>
                                                    <div className={styles['list-editing-card__desktop-wrapper']}>
                                                        <img
                                                            data-src={item?.media.length > 0 ? item?.media[0]?.url : product}
                                                            height={'77'}
                                                            className={'lazyload'}
                                                            alt='test' />
                                                        <div>
                                                            <h4>
                                                                {item?.nameUa}
                                                            </h4>
                                                            <p className={style.price_wrapper}>

                                                                {
                                                                    item?.price?.discountPrice ?   <span className={style.sale}>
                                                                        { item?.price?.discountPrice } грн
                                                                    </span> : null
                                                                }

                                                                {
                                                                    item?.price?.price ?  <span className={item?.price?.discountPrice ? style.sale_span : null}>
                                                                        { item?.price?.price } грн
                                                                    </span> : null
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <Button
                                                        name={'У кошик'}
                                                        cart={{
                                                            quantity  : 1,
                                                            productId : item?.id,
                                                            // eslint-disable-next-line more/no-duplicated-chains
                                                            price     : item?.price?.discountPrice || item?.price?.price || 0,
                                                            // eslint-disable-next-line more/no-duplicated-chains
                                                            image     : item?.media.length > 0 ? item?.media[0].url : '',
                                                            name      : item?.nameUa,
                                                            index     : i
                                                        }}
                                                        event={closeSearch}
                                                        clsTitle={styles.first__btn}>
                                                        <p>
                                                            Додано
                                                        </p>
                                                        <CartIcon />
                                                    </Button>
                                                </div>
                                                ))
                                            }
                                        </div>

                                        {searchProductsList.length
                                            ? <div className={style.link}>
                                                <Link onClick={closeSearch} to={`/search?searchText=${searchString}`}>
                                                    Показати всі результати пошуку
                                                </Link>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </CSSTransition>

                            {/* <button className={style.btn}>*/}
                            {/*    <MicroIcon />*/}
                            {/* </button>*/}

                            <span className={style.span} />

                            <button className={style.btn}>
                                <SearchIcon />
                            </button>
                        </div>
                    }
                    {
                        isDesktop &&
                        <div className={style['btn-wrapper']}>
                            <button
                                data-id='POPUP_DRAG'
                                className={classNames(style['btn-info'], '--border')}
                                onClick={togglePopup}
                            >
                                <DocumentIcon />
                                Замовити по фото рецепта
                            </button>

                            <button
                                className={classNames(style['btn-info'], '--border')}
                                onClick={togglePopup}
                                data-id='POPUP_CONSULTATION'
                            >
                                <OnlineIcon />
                                Експерт-фармацевт
                            </button>
                        </div>
                    }
                    {
                        !isTablet &&

                        <div className={style['mobile-btn']}>
                            <button className={style['btn-blue']} onClick={locationPopupHandle}>
                                <MarkerIcon />
                                <span>
                                    { city }
                                </span>
                            </button>


                            <CSSTransition
                                timeout={300}
                                in={visuallyImpaired}
                                unmountOnExit
                                classNames='item'
                                mode={'out-in'}
                            >
                                <div className={style.mobile_eye}>
                                    <button
                                        data-attr='small' className={stylesHeader.btn_a} style={{ marginLeft: 5 }}
                                        onClick={handleSmall}>
                                        A-
                                    </button>

                                    <button data-attr='base' className={stylesHeader.btn_a} onClick={handleBase}>
                                        A0
                                    </button>

                                    <button data-attr='big' className={stylesHeader.btn_a} onClick={handleBig}>
                                        A+
                                    </button>
                                </div>
                            </CSSTransition>

                            <button className={style['btn-blue']} onClick={mobileVisibleImpaired}>
                                <EyeIcon />
                            </button>

                            <CSSTransition
                                timeout={300}
                                in={location}
                                unmountOnExit
                                classNames='item'
                                mode={'out-in'}
                            >
                                <LocationPopup
                                    classTitle={style.mobile_location}
                                    classBtn={style.btn}
                                    event={locationPopupHandle}
                                />
                            </CSSTransition>

                            <button>
                                <HeartIcon />
                            </button>

                            <a
                                onClick={checkLogin}
                                href={'#'}
                                className={style.icon}
                            >
                                {
                                    checkToken ?
                                        <img
                                            src={accountPeople}
                                            style={{
                                                width : 27
                                            }}
                                        />
                                        : <ProfileIcon />
                                }
                            </a>
                        </div>
                    }
                    <button className={classNames(style.cart)} onClick={toggleCartPopup}>
                        <CartIcon />
                        <span className={'--border'}>
                            { items.length > 0 ? items.length : 0 }
                        </span>
                    </button>

                    <CSSTransition
                        timeout={300}
                        in={cartPopup}
                        unmountOnExit
                        classNames='item'
                        mode={'out-in'}
                    >
                        <PopupCart name={popupCartWrapper} position={positionChange} event={toggleCartPopup} />
                    </CSSTransition>
                </div>
                {
                    !isTablet &&
                    <>
                        <div className={style['border-mobile']} />
                        <div className={classNames(style['input-wrapper'], '--border')}>
                            <button className={style.button}>
                                <SearchIcon />
                            </button>
                            <input
                                type={'text'}
                                onChange={search}
                                ref={input}
                                placeholder={'пошук по 25 000 найменувань'}
                            />

                            <CSSTransition
                                timeout={300}
                                in={openSearch}
                                unmountOnExit
                                classNames='item'
                                mode={'out-in'}
                            >
                                <div className={style['input-search-popup']} onClick={handleClick}>
                                    <div className={style.bg} />
                                    <div ref={popupBody} className={style['input-search-popup__wrapper']}>
                                        <div ref={popupBodyScroll} className={style['input-search-popup__wrapper__card']}>
                                            {!searchProductsList.length
                                                ? renderSpinnerOrEmptyList()
                                                : null
                                            }

                                            {
                                                searchProductsList.slice(0, 3).map((item, i) => (
                                                    <div key={item?.id} className={styles['list-editing-card__card']}>
                                                        <div className={styles['list-editing-card__desktop-wrapper']}>
                                                            <img
                                                                data-src={item?.media.length > 0 ? item?.media[0]?.url : product}
                                                                height={'77'}
                                                                className={'lazyload'}
                                                                alt='test' />
                                                            <div>
                                                                <h4>
                                                                    {item?.nameUa}
                                                                </h4>
                                                                <p>
                                                                    {item?.price?.price} грн
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <Button
                                                            name={'У кошик'}
                                                            cart={{
                                                                quantity  : 1,
                                                                productId : item?.id,
                                                                // eslint-disable-next-line more/no-duplicated-chains
                                                                price     : item?.price?.price || 50,
                                                                // eslint-disable-next-line more/no-duplicated-chains
                                                                image     : item?.media.length > 0 ? item?.media[0].url : '',
                                                                name      : item?.nameUa,
                                                                index     : i
                                                            }}
                                                            event={closeSearch}
                                                            clsTitle={styles.first__btn}>
                                                            <p>
                                                                Додано
                                                            </p>
                                                            <CartIcon />
                                                        </Button>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        {searchProductsList.length
                                            ? <div className={style.link}>
                                                <Link onClick={closeSearch} to={`/search?searchText=${searchString}`}>
                                                    Показати всі результати пошуку
                                                </Link>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </CSSTransition>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

Search.propTypes = {
    isDesktop              : PropTypes.bool.isRequired,
    isTablet               : PropTypes.bool.isRequired,
    toggleCatalog          : PropTypes.func.isRequired,
    checkLogin             : PropTypes.func.isRequired,
    city                   : PropTypes.string,
    handleVisuallyImpaired : PropTypes.func
};

export default Search;
